import '../../app/vendors/css/forms/select/select2.min.css';
import '../../app/css/plugins/forms/pickers/form-flat-pickr.css';
import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';

import flatpickr from "flatpickr";
import select2 from 'select2';
import {initGlsService, unloadGlsService} from "./gls_service";
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onGlsNewParcelForm_7a899737(event) {
    toggleLoading('#saveButton', '#loadingButton');
    event.preventDefault();
    let form = $(this);
    let fd = new FormData(form[0]);
    let helper = $('#helper');
    let inputs = $(this).find("input, select, button, textarea");
    let url = helper.data('url');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        cache: false,
        contentType: false,
        processData: false,
        async: true
    }).done(function (response) {
        if (response.success) {
            if ($('#print').is(':checked')) {
                let a = document.createElement('a');
                if (window.URL && window.Blob && ('download' in a) && window.atob) {
                    let blob = base64ToBlob(response.data.data, response.data.mimetype);
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = response.data.filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            }

            swalSuccess(response.data.message);
            form.trigger("reset");
            form.removeClass("was-validated");
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButton_7a899737(event) {
    let form = $("#new-gls-parcel-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onOrderSelect_7a899737(e) {
    $('#reference').val(e.params.data.order);
    $('#cod_reference').val(e.params.data.order);
    $('#cod').val(e.params.data.number);
    $('#note').val(e.params.data.note);
    $('#sender_name').val(e.params.data.sender.name);
    $('#sender_country').val(e.params.data.sender.country);
    $('#sender_zip').val(e.params.data.sender.zip);
    $('#sender_city').val(e.params.data.sender.city);
    $('#sender_street').val(e.params.data.sender.street);
    $('#sender_house_nr').val(e.params.data.sender.house_nr);
    $('#sender_address_extra').val(e.params.data.sender.address_extra);
    $('#sender_contact').val(e.params.data.sender.contact_name);
    $('#sender_phone').val(e.params.data.sender.phone);
    $('#sender_email').val(e.params.data.sender.email);
    $('#recipient_name').val(e.params.data.recipient.name);
    $('#recipient_country').val(e.params.data.recipient.country);
    $('#recipient_zip').val(e.params.data.recipient.zip);
    $('#recipient_city').val(e.params.data.recipient.city);
    $('#recipient_street').val(e.params.data.recipient.street);
    $('#recipient_house_nr').val(e.params.data.recipient.house_nr);
    $('#recipient_address_extra').val(e.params.data.recipient.address_extra);
    $('#recipient_contact').val(e.params.data.recipient.contact_name);
    $('#recipient_phone').val(e.params.data.recipient.phone);
    $('#recipient_email').val(e.params.data.recipient.email);

    if (e.params.data.maybe_foxpost) {
        $('#foxpost-warning').removeClass('d-none');
    } else {
        $('#foxpost-warning').addClass('d-none');
    }
}

function onPrintChange_7a899737() {
    if ($('#print').is(':checked')) {
        $('#position-div').slideDown();
    } else {
        $('#position-div').slideUp();
    }
}

export function initGlsNewParcelPage() {
    window.flatpickr = flatpickr;
    window.select2 = select2;

    initGlsService();

    if ($('html').attr('lang') === 'hu') {
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
        require('select2/dist/js/i18n/hu.js');
    }

    let helper = $('#helper');
    let orderId = $("#order_id");

    window.formatInvoice = function (invoice) {
        if (invoice.loading) {
            return invoice.text;
        }

        return "<div>" +
            "<div class='fs-lg fw-500 mb-1'>" + invoice.order + "</div>" +
            "<div class='row fs-sm'>" +
            "<div class='col-lg-4'><b>" + helper.data('date') + ":</b><br />" + invoice.date + "</div>" +
            "<div class='col-lg-4'><b>" + helper.data('name') + ":</b><br />" + invoice.name + "</div>" +
            "<div class='col-lg-4'><b>" + helper.data('total') + ":</b><br />" + invoice.total + "</div>" +
            "</div>" +
            "</div>";
    };

    window.formatInvoiceSelection = function (invoice) {
        return invoice.order || invoice.text;
    };

    window.base64ToBlob = function (base64, mimetype, slicesize) {
        if (!window.atob || !window.Uint8Array) {
            return null;
        }

        let mime = mimetype || '';
        let chunk = slicesize || 512;
        let bytechars = atob(base64);
        let bytearrays = [];

        for (let offset = 0; offset < bytechars.length; offset += chunk) {
            let slice = bytechars.slice(offset, offset + chunk);
            let bytenums = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                bytenums[i] = slice.charCodeAt(i);
            }
            bytearrays[bytearrays.length] = new Uint8Array(bytenums);
        }

        return new Blob(bytearrays, {type: mime});
    };

    $('#DDS_date').flatpickr({
        dateFormat: "Y.m.d.",
        allowInput: true
    });

    orderId.select2({
        ajax: {
            url: helper.data('list'),
            dataType: 'json',
            delay: 250,
            cache: true,
            type: 'post',
            async: true,
            data: function (params) {
                return {
                    q: params.term,
                    page: params.page
                };
            },
            processResults: function (data, params) {
                params.page = params.page || 1;

                return {
                    results: data.items,
                    pagination: {
                        more: (params.page * 30) < data.total_count
                    }
                };
            }
        },
        placeholder: helper.data('select'),
        escapeMarkup: function (markup) {
            return markup;
        },
        minimumInputLength: 1,
        templateResult: formatInvoice,
        templateSelection: formatInvoiceSelection
    });

    orderId.on("select2:select", onOrderSelect_7a899737);
    $('#print').on('change', onPrintChange_7a899737);
    $("#saveButton").on("click", onSaveButton_7a899737);
    $("#new-gls-parcel-form").on('submit', onGlsNewParcelForm_7a899737);
}

export function unloadGlsNewParcelPage() {
    unloadGlsService();

    let orderId = $('#order_id');

    delete window.flatpickr;
    delete window.select2;
    delete window.formatInvoice;
    delete window.formatInvoiceSelection;
    delete window.base64ToBlob;

    orderId.select2('destroy');
    $('.flatpickr-calendar').remove();

    orderId.off("select2:select", onOrderSelect_7a899737);
    $('#print').off('change', onPrintChange_7a899737);
    $("#saveButton").off("click", onSaveButton_7a899737);
    $("#new-gls-parcel-form").off('submit', onGlsNewParcelForm_7a899737);
}
