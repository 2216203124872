function checkFDS_ab872c82() {
    let fds = $('#FDS');
    let twelve = $('#T12');
    let dds = $('#DDS');
    let sds = $('#SDS');
    let sat = $('#SAT');
    let adr = $('#ADR');

    if (fds.is(':checked')) {
        twelve.prop('checked', false).prop('disabled', true);
        dds.prop('checked', false).prop('disabled', true);
        sds.prop('checked', false).prop('disabled', true);
        sat.prop('checked', false).prop('disabled', true);
        adr.prop('checked', false).prop('disabled', true);
    } else {
        twelve.prop('disabled', false);
        dds.prop('disabled', false);
        sds.prop('disabled', false);
        sat.prop('disabled', false);
        adr.prop('disabled', false);
        fds.prop('checked', false);
    }
}

function checkFSS_ab872c82() {
    let fss = $('#FSS');
    let fds = $('#FDS');

    if (fss.is(':checked')) {
        fds.prop('checked', true);
    }

    checkFDS_ab872c82();
}

function checkSM2_ab872c82() {
    let sms = $('#SM2');
    let sds = $('#SDS');
    let sat = $('#SAT');

    if (sms.is(':checked')) {
        sds.prop('checked', false).prop('disabled', true);
        sat.prop('checked', false).prop('disabled', true);
    } else {
        sds.prop('disabled', false);
        sat.prop('disabled', false);
    }
}

function check24H_ab872c82() {
    let twentyfour = $('#24H');
    let dds = $('#DDS');
    let adr = $('#ADR');

    if (twentyfour.is(':checked')) {
        dds.prop('checked', false).prop('disabled', true);
        adr.prop('checked', false).prop('disabled', true);
    } else {
        dds.prop('disabled', false);
        adr.prop('disabled', false);
    }
}

function checkT12_ab872c82() {
    let fds = $('#FDS');
    let twelve = $('#T12');
    let fss = $('#FSS');
    let sds = $('#SDS');
    let sat = $('#SAT');

    if (twelve.is(':checked')) {
        fds.prop('checked', false).prop('disabled', true);
        fss.prop('checked', false).prop('disabled', true);
        sds.prop('checked', false).prop('disabled', true);
        sat.prop('checked', false).prop('disabled', true);
    } else {
        fds.prop('disabled', false);
        fss.prop('disabled', false);
        sds.prop('disabled', false);
        sat.prop('disabled', false);
    }
}

function checkDDS_ab872c82() {
    let dds = $('#DDS');
    let adr = $('#ADR');
    let fss = $('#FSS');
    let twentyfour = $('#24H');
    let sat = $('#SAT');
    let fds = $('#FDS');

    if (dds.is(':checked')) {
        $('#DDS_date_div').slideDown();
        fds.prop('checked', false).prop('disabled', true);
        fss.prop('checked', false).prop('disabled', true);
        twentyfour.prop('checked', false).prop('disabled', true);
        sat.prop('checked', false).prop('disabled', true);
        adr.prop('checked', false).prop('disabled', true);
    } else {
        $('#DDS_date_div').slideUp();
        fds.prop('disabled', false);
        fss.prop('disabled', false);
        twentyfour.prop('disabled', false);
        sat.prop('disabled', false);
        adr.prop('disabled', false);
    }
}

function checkSDS_ab872c82() {
    let fds = $('#FDS');
    let twelve = $('#T12');
    let sat = $('#SAT');
    let sms = $('#SM2');
    let fss = $('#FSS');
    let sds = $('#SDS');

    if (sds.is(':checked')) {
        $('#SDS_interval_div').slideDown();
        fds.prop('checked', false).prop('disabled', true);
        fss.prop('checked', false).prop('disabled', true);
        sms.prop('checked', false).prop('disabled', true);
        twelve.prop('checked', false).prop('disabled', true);
        sat.prop('checked', false).prop('disabled', true);
    } else {
        $('#SDS_interval_div').slideUp();
        fds.prop('disabled', false);
        fss.prop('disabled', false);
        sms.prop('disabled', false);
        twelve.prop('disabled', false);
        sat.prop('disabled', false);
    }
}

function checkSAT_ab872c82() {
    let fds = $('#FDS');
    let twelve = $('#T12');
    let dds = $('#DDS');
    let sds = $('#SDS');
    let sat = $('#SAT');
    let adr = $('#ADR');
    let fss = $('#FSS');
    let sms = $('#SM2');

    if (sat.is(':checked')) {
        fds.prop('checked', false).prop('disabled', true);
        fss.prop('checked', false).prop('disabled', true);
        sms.prop('checked', false).prop('disabled', true);
        twelve.prop('checked', false).prop('disabled', true);
        dds.prop('checked', false).prop('disabled', true);
        sds.prop('checked', false).prop('disabled', true);
        adr.prop('checked', false).prop('disabled', true);
    } else {
        fds.prop('disabled', false);
        fss.prop('disabled', false);
        sms.prop('disabled', false);
        twelve.prop('disabled', false);
        dds.prop('disabled', false);
        sds.prop('disabled', false);
        sat.prop('disabled', false);
    }
}

function checkADR_ab872c82() {
    let adr = $('#ADR');
    let twentyfour = $('#24H');
    let fds = $('#FDS');
    let fss = $('#FSS');
    let sat = $('#SAT');
    let dds = $('#DDS');

    if (adr.is(':checked')) {
        $('#ADR_comment_div').slideDown();
        twentyfour.prop('checked', false).prop('disabled', true);
        dds.prop('checked', false).prop('disabled', true);
        sat.prop('checked', false).prop('disabled', true);
        fds.prop('checked', false).prop('disabled', true);
        fss.prop('checked', false).prop('disabled', true);
    } else {
        $('#ADR_comment_div').slideUp();
        twentyfour.prop('disabled', false);
        dds.prop('disabled', false);
        sat.prop('disabled', false);
        fds.prop('disabled', false);
        fss.prop('disabled', false);
    }
}

function checkSZL_ab872c82() {
    let szl = $('#SZL');

    if (szl.is(':checked')) {
        $('#SZL_id_div').slideDown();
    } else {
        $('#SZL_id_div').slideUp();
    }
}

export function initGlsService() {
    let fds = $('#FDS');
    let twelve = $('#T12');
    let dds = $('#DDS');
    let sds = $('#SDS');
    let sat = $('#SAT');
    let adr = $('#ADR');
    let fss = $('#FSS');
    let sms = $('#SM2');
    let twentyfour = $('#24H');
    let szl = $('#SZL');

    checkFDS_ab872c82();
    checkFSS_ab872c82();
    checkSM2_ab872c82();
    check24H_ab872c82();
    checkT12_ab872c82();
    checkDDS_ab872c82();
    checkSDS_ab872c82();
    checkSAT_ab872c82();
    checkADR_ab872c82();

    fds.on('change', checkFDS_ab872c82);
    fss.on('change', checkFSS_ab872c82);
    sms.on('change', checkSM2_ab872c82);
    twentyfour.on('change', check24H_ab872c82);
    twelve.on('change', checkT12_ab872c82);
    dds.on('change', checkDDS_ab872c82);
    sds.on('change', checkSDS_ab872c82);
    sat.on('change', checkSAT_ab872c82);
    adr.on('change', checkADR_ab872c82);
    szl.on('change', checkSZL_ab872c82);
}

export function unloadGlsService() {
    let fds = $('#FDS');
    let twelve = $('#T12');
    let dds = $('#DDS');
    let sds = $('#SDS');
    let sat = $('#SAT');
    let adr = $('#ADR');
    let fss = $('#FSS');
    let sms = $('#SM2');
    let twentyfour = $('#24H');
    let szl = $('#SZL');

    fds.off('change', checkFDS_ab872c82);
    fss.off('change', checkFSS_ab872c82);
    sms.off('change', checkSM2_ab872c82);
    twentyfour.off('change', check24H_ab872c82);
    twelve.off('change', checkT12_ab872c82);
    dds.off('change', checkDDS_ab872c82);
    sds.off('change', checkSDS_ab872c82);
    sat.off('change', checkSAT_ab872c82);
    adr.off('change', checkADR_ab872c82);
    szl.off('change', checkSZL_ab872c82);
}
